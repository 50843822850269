import React from "react"
import Layout from "../components/layout";
import Title from "../components/title";
import Promo from "../components/promo";

const Terms = ({ location }) => (
    
    <Layout 
      path={location.pathname}>

      <Title
        title="Privacy Policy" />

      <div className="container max-w-3xl mx-auto">

        <div className="richtext mx-auto container space-y-8">

          <h2>1. Terms</h2>

          <p>Please read these Terms of Use fully and carefully before using https://heyslate.com (the “Site”) and the services, features, content or applications offered by Slate (Slate, “we”, “us” or “our”) (together with the Site, the “Service”). These Terms of Use set forth the legally binding terms and conditions for your use of the Site and the Service.</p>

          <p>By accessing the Site, you are agreeing to be bound by these Terms of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws.</p>

          <p>If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>

          <h2>2. Use License</h2>

          <ul>
            <li>
              Permission is granted to temporarily download one copy of the materials
              (information or software) on the Site for personal,
              non-commercial transitory viewing only. This is the grant of a license, not
              a transfer of title, and under this license you may not:
              <ul>
                <li>modify or copy the materials;</li>
                <li>
                  use the materials for any commercial purpose, or for any public display
                  (commercial or non-commercial);
                </li>
                <li>
                  attempt to decompile or reverse engineer any software contained on
                  the Site;
                </li>
                <li>
                  remove any copyright or other proprietary notations from the materials;
                  or
                </li>
                <li>
                  transfer the materials to another person or "mirror" the materials on
                  any other server.
                </li>
              </ul>
            </li>
            <li>
              This license shall automatically terminate if you violate any of these
              restrictions and may be terminated by Slate at any time. Upon
              terminating your viewing of these materials or upon the termination of this
              license, you must destroy any downloaded materials in your possession
              whether in electronic or printed format.
            </li>
          </ul>

          <h2>3. Disclaimer</h2>

          <ul>
            <li>
              The materials on the Site are provided on an 'as is' basis.
              Slate makes no warranties, expressed or implied, and hereby
              disclaims and negates all other warranties including, without limitation,
              implied warranties or conditions of merchantability, fitness for a
              particular purpose, or non-infringement of intellectual property or other
              violation of rights.
            </li>
            <li>
              Further, Slate does not warrant or make any representations
              concerning the accuracy, likely results, or reliability of the use of the
              materials on its website or otherwise relating to such materials or on any
              sites linked to this site.
            </li>
          </ul>

          <h2>4. Billing</h2>

          <ul>
            <li>Any free trial that provides access to the Service must be used within the specified time of the trial.</li>
            <li>You must stop using the Service before the end of the trial period in order to avoid being charged for the Service.</li>
            <li>If you cancel prior to the end of the trial period and are inadvertently charged for the Service, please contact us at <a href="mailto:hello@heyslate.com">hello@heyslate.com</a>.</li>
            <li>After the trial period ends, we will charge your credit card on a monthly or annual basis until such time as you cancel the Service.</li>
            <li>Except when required by law, payments are non-refundable.</li>
            <li>All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and you shall be responsible for payment of all such taxes, levies, or duties.</li>
            <li>You acknowledge and agree that any credit card and related billing and payment information that you provide to Slate may be shared by us with companies who work on our behalf, such as payment processors and/or credit agencies, solely for the purposes of checking credit, effecting payment to Slate and servicing Your account.</li>
            <li>Slate may change its fees and payment policies for the Service at any time. Unless otherwise noted on your account, you will continue paying the monthly or annual amount you originally agreed upon – regardless of our new pricing.</li>
          </ul>

          <h2>5. Cancellation</h2>

          <ul>
            <li>You can cancel the Service at any time by visiting <a href="https://app.heyslate.com/settings" target="_blank">https://app.heyslate.com/settings</a> or emailing <a href="mailto:hello@heyslate.com">hello@heyslate.com</a></li>
            <li>If you cancel the Service before the end of your current paid up month, your cancellation will take effect immediately and you will not be charged again.</li>
            <li>You agree that Slate, in its sole discretion and for any or no reason, may terminate or suspend your account. You agree that any termination of your access to the Service may be without prior notice, and you agree that Slate will not be liable to you or any third party for such termination.</li>
          </ul>

          <h2>6. Limitations</h2>

          <p>In no event shall Slate or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on the Site, even if Slate or a Slate authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>

          <h2>7. Accuracy of materials</h2>

          <p>The materials appearing on the Site website could include technical, typographical, or photographic errors. Slate does not warrant that any of the materials on its website are accurate, complete or current. Slate may make changes to the materials contained on its website at any time without notice. However Slate does not make any commitment to update the materials.</p>

          <h2>8. Links</h2>

          <p>Slate has not reviewed all of the sites linked to the Site and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Slate of the Site. Use of any such linked website is at the user's own risk.</p>

          <h2>9. Modifications</h2>

          <p>Slate may revise these Terms of Use for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these Terms of Use.</p>

          <h2>10. Governing Law</h2>

          <p>These terms and conditions are governed by and construed in accordance with the laws of The United Kingdom and you irrevocably submit to the exclusive jurisdiction of the courts in that country.</p>

        </div>

      </div>

      <Promo theme="light" />

    </Layout>
)

export default Terms;